import React from "react";
import { Container, Row } from "react-bootstrap";
import Layout from "../components/layout/MainLayout";
import { ColWrap, Heading1 } from "./contact";

const ThankYouPage = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <ColWrap>
            <Heading1 style={{ textAlign: "center" }}>
              Thank you for contacting us!
            </Heading1>
            <p
              style={{
                textAlign: "center",
                maxWidth: "600px",
                margin: "auto"
              }}>
              Someone will contact you shortly! In the meantime, check out the
              links above or visit the North Texas Municipal District website by
              clicking the button below.
            </p>
          </ColWrap>
        </Row>
      </Container>
    </Layout>
  );
};

export default ThankYouPage;
